import type React from 'react';
import { FC, useEffect, useState } from 'react';
import MuiLinearProgress from '@mui/material/LinearProgress';
import classNames from 'classnames';
import NextImage from 'next/image';

import { useAppSelector } from '@/hooks';
import { FlowModel } from '@/models/flow.model';

import styles from './OnboardingProgress.module.scss';


type OnboardingProgressProps = {
  stepsCount?: number;
  flow: FlowModel;
} & React.AllHTMLAttributes<unknown>

export const OnboardingProgress: FC<OnboardingProgressProps> = (
  {
    className,
    flow,
    ...allAttr
  },
) => {
  const stepsCount = flow.getSteps().length;
  const currentStep = useAppSelector(state => state.onboarding.currentStep);
  const currentStepIndex = flow.getSteps().findIndex(step => step.id === currentStep.id);
  const [progressVal, setProgressVal] = useState(0);

  useEffect(() => {
    setProgressVal((currentStepIndex + 1) / stepsCount * 100);
  }, [currentStep, currentStepIndex, stepsCount]);

  return (
    <div
      className={ classNames(styles.OnboardingProgress, className) }
      style={{ '--steps-count': stepsCount } as React.CSSProperties}
      { ...allAttr }
    >
      <div className={ styles.OnboardingProgress__Content }>
        <NextImage src={ '/assets/logo-full.svg' } width={ 150 } height={ 32 } alt="TD Logo" />
        <span
          id="progress-label"
          className="color-secondary-brown ft-size-14"
        >
          Step { currentStepIndex + 1 } of { stepsCount }
        </span>
      </div>
      <MuiLinearProgress
        variant="determinate"
        value={ progressVal }
        aria-labelledby="progress-label"
        className={ styles.progressBar }
      />
    </div>
  );
};
