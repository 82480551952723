import { FC, useEffect, useState } from 'react';
import { useGrowthBook } from '@growthbook/growthbook-react';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';

import { defaultFlowModel } from '@/features/default-flow/models/default-flow.model';
import { useAppSelector, useLoader, useTracking } from '@/hooks';
import { FlowModel } from '@/models/flow.model';

import CompanyRequests from '../../requests/company.requests';
import { OnboardingStepsMap, StepInfo } from '../../types';
import { RenderIf } from '../RenderIf';

import styles from './Step.module.scss';

type StepProps = Omit<StepInfo<OnboardingStepsMap>, 'Component'> & {
  flow?: FlowModel;
}

export const Step: FC<StepProps> = (
  {
    id,
    stepName,
    title,
    desc,
    Info,
    children,
    flow = defaultFlowModel,
  },
) => {
  const { company } = useAppSelector(state => state.auth);
  const { trackingMode, hasCompletedOnboarding, currentStep } = useAppSelector(state => state.onboarding);
  const [latestStep, setLatestStep] = useState(id);

  const { t } = useTranslation();
  const { showLoader } = useLoader();
  const { trackStepChangePage, trackStepChangeEvent } = useTracking(flow);
  const growthBook = useGrowthBook();

  useEffect(() => {
    // Update the onboarding_step attribute in GrowthBook on every step change
    if (growthBook) growthBook.setAttributes({ ...growthBook.getAttributes(), onboarding_step: id });

    if (currentStep.isDefault) {
      setLatestStep(id);
      return;
    }

    if (
      latestStep !== id &&
      stepName !== 'Invite Employees Screen' &&
      stepName !== 'Expandable Invite Employees Screen'
    ) {
      trackStepChangeEvent(trackingMode);
      trackStepChangePage(stepName);

      CompanyRequests.putCompanySettings(company?.id || '', {
        custom: { onboardingStep: id },
      });

      setLatestStep(id);
    }
  }, [id, stepName, company, currentStep, latestStep, trackingMode, trackStepChangeEvent, trackStepChangePage]);

  useEffect(() => {
    if (hasCompletedOnboarding) showLoader();
  }, [hasCompletedOnboarding, showLoader]);

  return (
    <>
      <header className={ classNames(styles.Step__TextWrap) }>
        <h3 className={ styles.Step__TextWrap__Title }>{ t(title) }</h3>

        { desc && <h4 className={ styles.Step__TextWrap__Desc }>{ t(desc) }</h4> }
      </header>

      { children }

      <RenderIf condition={ !!Info }>
        <p className={ classNames(styles.Step__Info, 'color-secondary-brown') }>
          <InfoIcon fontSize="small" />
          <span className="ft-size-14">{ Info }</span>
        </p>
      </RenderIf>
    </>
  );
};

